import { computed, ref, inject, provide } from '@nuxtjs/composition-api'
import { isBefore, isAfter, isSameDay } from 'date-fns'

function isEmpty(value) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'number' && Number.isNaN(value)) ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  )
}

export const CURRENT_FIELD_VALUE_INDICATOR = '$this'

const conditionTypesSymbol = Symbol('conditionTypesSymbol')

const isCheck = (expectedValue, sourceValue) =>
  String(expectedValue).toLowerCase() === String(sourceValue).toLowerCase()

const containsCheck = (expectedValue, sourceValue) => {
  const normalizedSourceValue = Array.isArray(sourceValue)
    ? sourceValue.join(', ')
    : sourceValue

  return (String(normalizedSourceValue) || '')
    .toLowerCase()
    .includes(String(expectedValue).toLowerCase())
}

const emptyCheck = (_expectedValue, sourceValue) => isEmpty(sourceValue)

const isAfterCheck = (expectedValue, sourceValue) => {
  if (!sourceValue || !expectedValue) return false
  return isAfter(new Date(sourceValue), new Date(expectedValue))
}

const isBeforeCheck = (expectedValue, sourceValue) => {
  if (!sourceValue || !expectedValue) return false
  return isBefore(new Date(sourceValue), new Date(expectedValue))
}

const isSameDateCheck = (expectedValue, sourceValue) => {
  if (!sourceValue || !expectedValue) return false
  return isSameDay(new Date(sourceValue), new Date(expectedValue))
}

const isGreaterThanCheck = (expectedValue, sourceValue) => {
  if (!sourceValue || !expectedValue) return false
  return sourceValue > expectedValue
}

const isLowerThanCheck = (expectedValue, sourceValue) => {
  if (!sourceValue || !expectedValue) return false
  return sourceValue < expectedValue
}

const BASE_CONDITION_TYPES = {
  is: isCheck,
  isNot: (expectedValue, sourceValue) => !isCheck(expectedValue, sourceValue),
  contains: containsCheck,
  doesNotContain: (expectedValue, sourceValue) =>
    !containsCheck(expectedValue, sourceValue),
  isEmpty: emptyCheck,
  isNotEmpty: (expectedValue, sourceValue) =>
    !emptyCheck(expectedValue, sourceValue),
  isAfter: isAfterCheck,
  isBefore: isBeforeCheck,
  isSameDate: isSameDateCheck,
  isGreaterThan: isGreaterThanCheck,
  isLowerThan: isLowerThanCheck,
}

const VALUELESS_TYPES = ['isEmpty', 'isNotEmpty']

export default function useConditionTypes() {
  const conditionTypesArray = inject(
    conditionTypesSymbol,
    ref([BASE_CONDITION_TYPES])
  )
  provide(conditionTypesSymbol, conditionTypesArray)

  const conditionTypes = computed(() =>
    conditionTypesArray.value.reduce(
      (types, type) => ({ ...types, ...type }),
      {}
    )
  )

  /**
   * @example
   * addTypes({
   *  someNewType: () => {}
   * })
   */
  function addTypes(typeOrTypes) {
    const types = Array.isArray(typeOrTypes) ? typeOrTypes : [typeOrTypes]
    conditionTypesArray.value.push(...types)
  }

  /**
   * Returns a method to compare item with source value of a given index.
   * Most of the time compares item value with source value.
   * fieldValue is taken into consideration only when item.value === '$this'
   */
  function evaluateCondition(sourceValues, fieldValue, formValue) {
    return (item, index) =>
      isPassingCondition({
        type: item.type,
        expected: item.value,
        sourceValue: sourceValues[index],
        thisValue: fieldValue,
        formValue: formValue,
      })
  }

  function isPassingCondition({
    type,
    expected,
    sourceValue,
    thisValue,
    formValue,
  }) {
    let expectedValue
    if (expected === CURRENT_FIELD_VALUE_INDICATOR) {
      expectedValue = thisValue
    } else if (expected && String(expected).startsWith('$')) {
      const selectedFormField = expected.slice(1)
      expectedValue = formValue[selectedFormField]
    } else {
      expectedValue = expected
    }

    const check = conditionTypes.value[type]
    if (check) return check(expectedValue, sourceValue)
    throw Error(`Condition "${type}" is not supported`)
  }

  function isValuelessType(type) {
    return VALUELESS_TYPES.includes(type)
  }

  return {
    conditionTypes,
    addTypes,
    evaluateCondition,
    isValuelessType,
  }
}
